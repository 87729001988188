import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme, Link, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Otp from "../login/Otp/OtpField/index";
import { ReducersModal } from "../../modal";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitOtp, onSubmitEthical, onSendOtp } from "./action";
import Utils from "../../utils";
import Timer from "../../components/timer";
import { useNavigate } from "react-router-dom";
import { ifphoneTenDigitThenTrim } from "../../utils/commonFunctions";
const useStyles = makeStyles({
  leftScreen: {
    maxWidth: "400px",
    margin: "auto",
  },
  logo: {
    display: "flex",
    alignItems: "flex-start",
  },
  ResendBtn: ({ theme }: any) => ({
    fontSize: "14px",
    border: "none",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    color: "var(--bright-purple-mini)",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
  ResendBtnGrey: ({ theme }: any) => ({
    fontSize: "14px",
    backgroundColor: "#ffffff",

    border: "none",
    color: "#707070 !important",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
});

function PatientEthicalVerification(props:any) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { otp } = useSelector((state: ReducersModal) => state.otpReducer);
  const {
    patientCountryCode,
    entityId,
    phoneNumber,
    checked,
    finalEntityId,
    ethicalSubstitue
  } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );
  const { patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  const { beneficiary } = useSelector((state: ReducersModal)=> state.claimDetailReducer)

  const navigate = useNavigate();

  const { enableResend } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );

  const dispatch = useDispatch();

  const handleSubmit = (event: any, type: any) => {
    event.preventDefault();

    const fullPhone = `${patientCountryCode}${ifphoneTenDigitThenTrim(
      phoneNumber
    )}`
    const FinalEntityId = checked ? finalEntityId : entityId
    const ethicalSubs = ethicalSubstitue ? 1 : 0

    if ((otp.length === 4 || otp.length === 6) && type === 1) {
      dispatch(onSendOtp(fullPhone, patientInsurance, FinalEntityId, ethicalSubs, 'verify_otp', otp,entityId));
    } else if (type === 2) {
      dispatch(onSendOtp(fullPhone, patientInsurance, FinalEntityId, ethicalSubs, 'send_otp', false,entityId));
    } else {
      Utils.showAlert(2, "OTP required");
    }
  };

  const [timeText, setTimeText] = React.useState(0);

  const callbackFunction = (childData: any) => {
    setTimeText(childData);
  };

  return (
    <div className="page-patient-verification">
      <div className="bg-white mb-20 center">
        <h2 className="mb-15">OTP Verification</h2>
        <p>Please enter the {beneficiary?.card_type==2 ? 6 : 4} digit OTP sent to verify user</p>

        <div style={{ width: "300px", margin: "auto" }}>
          <Otp numInputs={beneficiary?.card_type==2 ? 6 : 4} />
        </div>

        <p className="mt-20">
          Did not receive OTP?{" "}

          <button
            type="button"
            disabled={enableResend}
            className={timeText > 0 ? classes.ResendBtnGrey : classes.ResendBtn}
            onClick={(event: any) => handleSubmit(event, 2)}
          >
            {"Resend Code "} {timeText > 0 ? "in" : ""}{" "}
            {timeText > 0 ? timeText : ""} {timeText > 0 ? "Sec" : ""}
          </button>
          <Timer parentCallback={callbackFunction} />
        </p>

        <div className="flex-center mt-20 mb-20">
          {/* <CustomButton
            className="capitalize color-white"
            sx={{ width: "auto" }}
            onClick={(event: any) => handleSubmit(event)}
          >
            Verify
          </CustomButton> */}
          <Button
            variant="contained"
            className="capitalize color-white"
            sx={{ width: "auto" }}
            //

            onClick={(event: any) => handleSubmit(event, 1)}
          >
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PatientEthicalVerification;
