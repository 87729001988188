// @ts-nocheck
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Link, CircularProgress, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import Otp from "../login/Otp/OtpField/index";
import FR from "../../components/facialRecognition";
import { ReducersModal } from "../../modal";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitOtp, getMemberFamilyDetails, getClaimDetails } from "./action";
import Utils from "../../utils";
import Timer from "../../components/timer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  leftScreen: {
    maxWidth: "400px",
    margin: "auto",
  },
  logo: {
    display: "flex",
    alignItems: "flex-start",
  },
  ResendBtn: ({ theme }: any) => ({
    fontSize: "14px",
    border: "none",
    backgroundColor: "#ffffff",
    cursor: "pointer",
    color: "var(--bright-purple-mini)",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
  ResendBtnGrey: ({ theme }: any) => ({
    fontSize: "14px",
    backgroundColor: "#ffffff",

    border: "none",
    color: "#707070 !important",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }),
});

function PatientVerification(props:any) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { otp } = useSelector((state: ReducersModal) => state.otpReducer);
  const { state }: any = useLocation();
  const dispatch = useDispatch();
  const [isStream, setIsStream] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showOTP, setShowOTP] = useState("");
  const { phone_number, phone_code } = useSelector(
    (state: ReducersModal) => state.createClaimSecondStepReducer
  );

  const { enableResend } = useSelector(
    (state: ReducersModal) => state.signInReducer
  );
  const {
    claimId,
    patientInsuranceMember,
    patientName,
    phoneNumber,
    authTypeSelected,
    policyStatus,
    authType,
  } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const { activeStep, claimSteps } = useSelector(
    (state: ReducersModal) => state.PatientInsuranceCompanyReducer
  );
  const {
    id,
    entity_id,
    insurance_name,
    insurance_company_name,
    insurance_company_id,
    user_id,
    user_phone,
    user_name,
    auth_type_selected,
    beneficiary,
    visitCode,
    user_claim_status
  } = useSelector((state: ReducersModal) => state.claimDetailReducer);

  const searchMember = (beneficiary) => {
    let beneficiaryName = "";
    if (beneficiary?.card_type === "1") {
      beneficiaryName = beneficiary?.entity_id;
    } else if (beneficiary?.card_type === "2") {
      beneficiaryName = beneficiary?.employee_number;
    } else if (beneficiary?.card_type === "3") {
      beneficiaryName = beneficiary?.employee_number;
    } else {
      beneficiaryName = beneficiary?.entity_id;
    }
    return beneficiaryName;
  };

  useEffect(() => {
    //   dispatch(
    //     getInsuredMember(insurance_company_id, () => {}, {search: entity_id})
    //   );
    if (authTypeSelected === "1" || auth_type_selected === "1") {
      setIsLoading(true);
      let data = ``;

      if (beneficiary.card_type === "2") {
        data = `?insurance_company_id=${insurance_company_id}&is_search_by_smartcard=${
          beneficiary.card_type === "2" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}&search=${searchMember(
          beneficiary
        )}`;
      } else {
        data = `?insurance_company_id=${insurance_company_id}&is_search_by_smartcard=${
          beneficiary.card_type === "2" ? 1 : 0
        }&smartcard_provider_name=${""}&from_desktop_portal=${1}&search=${searchMember(
          beneficiary
        )}&isSearchOnActisure=${true}`;
      }

      // if (insuranceCardType !== "1") {
      //   data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${
      //     insuranceCardType === "1" ? 1 : 0
      //   }&smartcard_provider_name=${""}&from_desktop_portal=${1}${
      //     search.length > 0 ? `&search=${searchVal}` : ""
      //   }&isSearchOnActisure=${true}`;
      // } else {
      //   data = `?insurance_company_id=${patientInsuranceId}&is_search_by_smartcard=${
      //     insuranceCardType === "1" ? 1 : 0
      //   }&smartcard_provider_name=${""}&from_desktop_portal=${1}${
      //     search.length > 0 ? `&search=${searchVal}` : ""
      //   }`;
      // }

      Utils.api.getApiCall(
        Utils.endPoints.searchInsuredMember,
        data,
        (respData: any) => {
          let { data } = respData;

          if (data.code !== 400) {
            if (data.body.length > 0) {
              dispatch({
                type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                payload: {
                  patientInsuranceMember: data.body,
                },
              });
              dispatch(
                getMemberFamilyDetails(
                  entity_id,
                  data.body[0].employee_number !== null
                    ? data.body[0].employee_number
                    : data.body[0].entity_id,
                  insurance_company_id,
                  data.body[0].user_id,
                  data.body[0].entity_id
                )
              );
              setIsLoading(false);
            } else {
              Utils.showAlert(2, "No result found");
              setIsLoading(false);
            }
          } else {
            dispatch({
              type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
              payload: {
                patientInsuranceMember: [],
              },
            });
            Utils.showAlert(2, data?.messages[0]);
            setIsLoading(false);
          }
        },
        (error: any) => {
          let { data } = error;

          dispatch({
            type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
            payload: {
              patientInsuranceMember: [],
            },
          });
          //;
          Utils.showAlert(2, data?.messages[0]);
          setIsLoading(false);
        }
      );
    }
  }, [entity_id, insurance_company_id]);

  const handleSubmit = (event: any, type: any) => {
    
    event.preventDefault();
    
    if (beneficiary?.card_type == 2 && visitCode && type==3) {
      const URI = `?Type=RequestOTP&otpType=2&visitCode=${visitCode}`;
      Utils.api.getApiCall(
        Utils.endPoints.smartCard+'/1',
        URI,
        (respData: any) => {
          if(respData.data.body.code==200){
            Utils.showAlert(1, 'OTP sent successfully');
          }else{
            Utils.showAlert(2, respData.data.body.message);
          }
        },
        (error: any) => {
          let { data } = error;
        }

      );
      return;
    }
    const claim = state?.edit ? id : claimId;
    if (!claim) {
      Utils.showAlert(2, "Claim id not found");
      return;
    }
    if ((otp.length === 4 || otp.length === 6) && type === 1) {
      dispatch(onSubmitOtp(otp, type, claim));
    } else if (type === 2) {
      dispatch(onSubmitOtp(otp, type, claim));
    } else if (type === 3) {
      dispatch(onSubmitOtp(otp, type, claim));
    } else {
      Utils.showAlert(2, "OTP required");
    }
  };

  const [timeText, setTimeText] = React.useState(0);

  const callbackFunction = (childData: any) => {
    setTimeText(childData);
  };

  useEffect(() => {
    if (authType.length == 1) {
      console.log("authType.length", authType.length, authType);
      if (authType[0] === "OTP") setShowOTP(true);
      else setShowOTP(false);
    }
    dispatch(getClaimDetails(claimId, "claimDeatil"));
  }, [showOTP]);

  return (
    <div className="page-patient-verification">
      {user_claim_status==2 || user_claim_status=="2" ? (
        <><div className="bg-white mb-20 center"> <p>Dear Provider, This claim went in Pre-Authorization status.</p></div></>
      ) : (
        <>
         {authTypeSelected === "0" ||
      authTypeSelected === null ||
      showOTP === true ? (
        <div className="bg-white mb-20 center">
          <h2 className="mb-15">OTP Verification</h2>
          <p>
            Please enter the {beneficiary?.card_type == 2 && (props.sixInput && props.sixInput !=undefined) ? 6 : 4} digit OTP
            sent to the patient with Phone Number{" "}
            {phone_code ? `+${phone_code}` : ""}-
            {phone_number && phone_code ? phone_number : ""}
          </p>
          <div style={{ width: "300px", margin: "auto" }}>
            <Otp numInputs={beneficiary?.card_type == 2 && (props.sixInput && props.sixInput !=undefined) ? 6 : 4} />
          </div>
          <p className="mt-20">
            Did not receive OTP?{" "}
            {/* <span
            style={{ color: "blue", fontWeight: "600", cursor: "pointer" }}
            onClick={(event: any) => handleSubmit(event, 2)}
          >
            Resend
          </span> */}
            <button
              type="button"
              disabled={enableResend}
              className={
                timeText > 0 ? classes.ResendBtnGrey : classes.ResendBtn
              }
              onClick={(event: any) => handleSubmit(event, 3)}
            >
              {"Resend Code "} {timeText > 0 ? "in" : ""}{" "}
              {timeText > 0 ? timeText : ""} {timeText > 0 ? "Sec" : ""}
            </button>
            <Timer parentCallback={callbackFunction} />
          </p>
          <div className="flex-center mt-20 mb-20">
            {/* <CustomButton
            className="capitalize color-white"
            sx={{ width: "auto" }}
            onClick={(event: any) => handleSubmit(event)}
          >
            Verify
          </CustomButton> */}
            <Button
              variant="contained"
              className="capitalize color-white"
              sx={{ width: "auto" }}
              //

              onClick={(event: any) => handleSubmit(event, 1)}
            >
              Verify
            </Button>
          </div>
          <h4>
            Incase the patient does not receive the verification code, please
            request them to whitelist their number by dialing 456955*1# <br />
            Once whitelisted, please click on Resend OTP.
          </h4>
          &nbsp;
          <h4>
            Please note: If the phone number is not correct, please contact us
            at <Link sx={{ color: "blue" }}>Ipcsupport@liviaapp.com</Link>.
          </h4>
        </div>
      ) : (
        ""
      )}
      {authTypeSelected === "1" || showOTP === false ? (
        <div className="bg-white mb-20 center">
          <h2 className="mb-15">Face Biometric Verification</h2>
          {isLoading && <CircularProgress color="primary" />}
          {!isLoading && (
            <FR
              setIsStream={setIsStream}
              entity_id={patientInsuranceMember[0]?.entity_id || entity_id}
              face_id={patientInsuranceMember[0]?.face_id}
              for_fr="eclaim"
              insuranceStatus={policyStatus == 1}
              patientName={patientName || user_name}
              insuranceCompanyName={
                patientInsuranceMember[0]?.insurance_company_name ||
                insurance_company_name ||
                insurance_name
              }
              phone_no={phoneNumber || user_phone}
              insurance_company_id={
                patientInsuranceMember[0]?.insurance_company_id ||
                insurance_company_id
              }
              insuranceNumber={searchMember(beneficiary)}
              principle_entity_id={
                patientInsuranceMember[0]?.principle_user_id || user_id
              } //principle_entity_id
            />
          )}
        </div>
      ) : (
        ""
      )}
        </>
      )}
     
    </div>
  );
}

export default PatientVerification;
