import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../../utils";
import { getFrUniqueIdInLS } from "../../utils/commonFunctions";

function ClaimAuthentication({ authType, authText }: any) {
  // const classes = useStyles({ theme });

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        showAuthenticationType: false,
      },
    });
  };
  const { showAuthenticationType, showOtpVerification } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const chooseAuthType = (event: any) => {
    dispatch({
      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
      payload: {
        showOtpVerification: event.target.value,
        authTypeSelected: event.target.value,        
        showAuthenticationType: false,       
        termsConditionChecked: false,
      },
    });
  };

  return (
    <Modal
      open={showAuthenticationType}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "10px",
          p: 2,
        }}
      >
        <div style={{ display: "flex" }}>
          <FormControl>
            <FormLabel>
              <Typography variant="h6">Authentication Type</Typography>
            </FormLabel>
            <RadioGroup
              className="mt-10"
              name={"Authentication Type"}
              value={showOtpVerification}
              onChange={(event: any) => chooseAuthType(event)}
            >
              {authType.length > 0 &&
                authType.map((item: any, index: any) => {
                  if (item.toLowerCase() === "fr") {
                    return (
                      <>
                        <FormControlLabel
                        // style={{display: "none"}}
                          key={index}
                          value={`${index}`}
                          control={<Radio />}
                          label={authText[item]}
                          disabled={!getFrUniqueIdInLS()}
                        />
                        {!getFrUniqueIdInLS() && (
                          <p
                            style={{
                              color: "#DD0000",
                              fontSize: "14px",
                              position: "relative",
                              top: "-10px",
                              left: "30px",
                            }}
                          >
                            No device is associated with your account for face biometric authentication. Please connect
                            with admin.
                          </p>
                        )}
                      </>
                    );
                  }
                  return <FormControlLabel key={index} value={`${index}`} control={<Radio />} label={authText[item]} />;
                })}
            </RadioGroup>
          </FormControl>
          {/* <CloseIcon onClick={() => handleClose()} style={{ cursor: "pointer" }} /> */}
        </div>
      </Box>
    </Modal>
  );
}

export default ClaimAuthentication;
