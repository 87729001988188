// import constant from "./constants";

import { toast } from "react-toastify";
import { toastIdsConstant } from "./toastIdsConstant";
import constants from "./constants";
import Utils from ".";
import moment from "moment";
import FormField from "../schema/formField";
import showAlert from "./alert";
import { statusArray, OrderStatus } from "./appData";
import { getDistance } from 'geolib';
import Geocode from "react-geocode";
const GOOGLE_MAPS_API_KEY: any = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const SUPPORT_NUMBER = "0114081492";

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();
// import { formulation } from "../schema/formField";
const UAP = process.env.REACT_APP_UAP
const EABL = process.env.REACT_APP_EABL
const KQ = process.env.REACT_APP_KQ
const JUBILEE = process.env.REACT_APP_JUBILEE
const JubKQ = [JUBILEE, KQ];

const MAD = process.env.REACT_APP_MAD
const FIRST_ASSURANCE = process.env.REACT_APP_FA

const PageSwitch = (value: any, history: any) => {
  // event.preventDefault();
  window.scrollTo(0, 0);
  history.push(value);
};

// interface customType {
//   default: void;
// }

const saveData = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  //@ts-ignore
  a.style = "display: none";
  //@ts-ignore
  return function (data, fileName) {
    const blob = new Blob([data], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const retry = (fn: any, ms: any) => {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          retry(fn, ms).then(resolve);
        }, ms);
      });
  });
};

/**
 * function to remove all spaces from a given string.
 * @param value
 */
const removeSpaces = (value: string) => {
  return value.replace(/\s/g, "");
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function checkFileSize(file: any) {
  const fileSize = file.size;
  const fsize = Math.round(fileSize / 1024);
  if (fsize >= 8192) {
    if (!toast.isActive(toastIdsConstant.fileSizeAlert)) {
      toast.warn("File should not be greater than 8 MB", {
        toastId: toastIdsConstant.fileSizeAlert,
        className: "toast-error",
      });
      return true;
    }
    return true;
  }
  return false;
}

// export function checkImageWAH(file: any) {
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.src = window.URL.createObjectURL(file.fileList[0]);
//     img.onload = () => {
//       if (img.width >= 512 && img.height >= 512) {
//         resolve(true);
//       } else {
//         showAlert(
//           2,
//           `Sorry, this image doesn't look like the size we wanted. It's
//         ${img.width} x ${img.height} but we require minimum  512 x 512 pixels size image.`
//         );
//         reject(false);
//       }
//     };
//   });
// }

export function getFileType(file: string): string {
  let fileType = "";
  if (file.includes(".pdf")) {
    fileType = "pdf";
  }
  if (file.includes(".doc")) {
    fileType = "doc";
  }
  if (file.includes(".xlsx")) {
    fileType = "xlsx";
  }
  return fileType;
}
// vnd.openxmlformats - officedocument.wordprocessingml.document;
export function checkFileType(files: any) {
  if (
    ![
      "jpg",
      "jpeg",
      "png",
      "gif",
      "pdf",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
      "doc",
      "msword",
    ].includes(files.fileList[0].type.split("/")[1])
  ) {
    if (!toast.isActive(toastIdsConstant.fileSizeAlert)) {
      toast.error("Sorry, only jpg, jpeg, png, gif, pdf ,xlsx, doc, docx files are supported.", {
        toastId: toastIdsConstant.imageUploadFailure,
        className: "toast-error",
      });
    }
    return true;
  }
  return false;
}

export const checkEFileType = (files: any) => {

  if (files.fileList[0].name.includes(".pdf") || files.fileList[0].name.includes(".PDF") || files.fileList[0].name.includes(".jpg") || files.fileList[0].name.includes(".JPG") || files.fileList[0].name.includes(".jpeg") || files.fileList[0].name.includes(".JPEG") || files.fileList[0].name.includes(".png") || files.fileList[0].name.includes(".PNG")) {
    //
  } else {
    return true;
  }
  return false;
};

export function checkImageWAH(file: any) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file.fileList[0]);
    img.onload = () => {
      if (img.width >= 512 && img.height >= 512) {
        resolve(true);
      } else {
        showAlert(
          2,
          "Uploaded image must be of minimum 512 x 512 pixels."
          //         `Sorry, this image doesn't look like the size we wanted. It's
          // ${img.width} x ${img.height} but we require minimum 512 x 512 pixels size image.`
        );
        reject(false);
      }
    };
  });
}

export const uploadDocumentImage = (binaryImage: any) => {
  Utils.constants.getAccessToken();
  return constants.axios.patch(Utils.endPoints.image, {
    image: binaryImage,
    type: "users",
  });
};

export const getDocumentImage = (claim_id: any) => {
  Utils.constants.getAccessToken();
  return constants.axios.get(Utils.endPoints.createClaim + "/" + claim_id);
};

export const deleteDocumentImage = (image: any) => {
  Utils.constants.getAccessToken();
  return constants.axios.delete(Utils.endPoints.image, {
    data: { image },
  });
};

export const deleteDocumentImageUploadPrescription = (payload: any) => {
  Utils.constants.getAccessToken();
  return constants.axios.delete(Utils.endPoints.image, {
    data: payload,
  });
};

export const convertFullDateToTime = (time: any) => {
  const y = moment(time).format("hh:mm:ss a");
  const s = moment(y, "HH:mm:ss: a").diff(moment().startOf("day"), "minutes");
  return s;
};

export const checkStatus = (status: number) => {
  if (status === 2) {
    return "Awaiting Pre-authorisation";
  } else if (status === 3) {
    return "Accepted";
  } else if (status === 4) {
    return "Declined";
  } else if (status === 6) {
    return "In Process";
  } else if (status === 7) {
    return "Loaded";
  } else if (status === 8) {
    return "Payment Ready";
  } else if (status === 9) {
    return "Settled";
  } else if (status === 1) {
    return "Draft Claim";
  } else {
    return "N/A";
  }
};

export const setTimeFielFuc = () => { };

export const convertPayloadData = ({
  // sun
  workingSwitchNameSun,
  workingTimeNameToSun,
  workingTimeNameFromSun,
  lunchSwitchNameSun,
  lunchTimeNameToSun,
  lunchTimeNameFromSun,
  // mon
  workingSwitchNameMon,
  workingTimeNameToMon,
  workingTimeNameFromMon,
  lunchSwitchNameMon,
  lunchTimeNameToMon,
  lunchTimeNameFromMon,
  // tue
  workingSwitchNameTue,
  workingTimeNameToTue,
  workingTimeNameFromTue,
  lunchSwitchNameTue,
  lunchTimeNameToTue,
  lunchTimeNameFromTue,
  // wed
  workingSwitchNameWed,
  workingTimeNameToWed,
  workingTimeNameFromWed,
  lunchSwitchNameWed,
  lunchTimeNameToWed,
  lunchTimeNameFromWed,
  // thu
  workingSwitchNameThu,
  workingTimeNameToThu,
  workingTimeNameFromThu,
  lunchSwitchNameThu,
  lunchTimeNameToThu,
  lunchTimeNameFromThu,
  // fri
  workingSwitchNameFri,
  workingTimeNameToFri,
  workingTimeNameFromFri,
  lunchSwitchNameFri,
  lunchTimeNameToFri,
  lunchTimeNameFromFri,
  // sat
  workingSwitchNameSat,
  workingTimeNameToSat,
  workingTimeNameFromSat,
  lunchSwitchNameSat,
  lunchTimeNameToSat,
  lunchTimeNameFromSat,
}: any) => {
  return [
    {
      day_type: !workingSwitchNameSun ? "0" : "1",
      start_work: !workingSwitchNameSun ? null : convertFullDateToTime(workingTimeNameToSun),
      end_work: !workingSwitchNameSun ? null : convertFullDateToTime(workingTimeNameFromSun),
      day_in_week: "0",
      start_lunch: !workingSwitchNameSun || !lunchSwitchNameSun ? null : convertFullDateToTime(lunchTimeNameToSun),
      end_lunch: !workingSwitchNameSun || !lunchSwitchNameSun ? null : convertFullDateToTime(lunchTimeNameFromSun),
    },
    {
      day_type: !workingSwitchNameMon ? "0" : "1",
      start_work: !workingSwitchNameMon ? null : convertFullDateToTime(workingTimeNameToMon),
      end_work: !workingSwitchNameMon ? null : convertFullDateToTime(workingTimeNameFromMon),
      day_in_week: "1",
      start_lunch: !workingSwitchNameMon || !lunchSwitchNameMon ? null : convertFullDateToTime(lunchTimeNameToMon),
      end_lunch: !workingSwitchNameMon || !lunchSwitchNameMon ? null : convertFullDateToTime(lunchTimeNameFromMon),
    },
    {
      day_type: !workingSwitchNameTue ? "0" : "1",
      start_work: !workingSwitchNameTue ? null : convertFullDateToTime(workingTimeNameToTue),
      end_work: !workingSwitchNameTue ? null : convertFullDateToTime(workingTimeNameFromTue),
      day_in_week: "2",
      start_lunch: !workingSwitchNameTue || !lunchSwitchNameTue ? null : convertFullDateToTime(lunchTimeNameToTue),
      end_lunch: !workingSwitchNameTue || !lunchSwitchNameTue ? null : convertFullDateToTime(lunchTimeNameFromTue),
    },
    {
      day_type: !workingSwitchNameWed ? "0" : "1",
      start_work: !workingSwitchNameWed ? null : convertFullDateToTime(workingTimeNameToWed),
      end_work: !workingSwitchNameWed ? null : convertFullDateToTime(workingTimeNameFromWed),
      day_in_week: "3",
      start_lunch: !workingSwitchNameWed || !lunchSwitchNameWed ? null : convertFullDateToTime(lunchTimeNameToWed),
      end_lunch: !workingSwitchNameWed || !lunchSwitchNameWed ? null : convertFullDateToTime(lunchTimeNameFromWed),
    },
    {
      day_type: !workingSwitchNameThu ? "0" : "1",
      start_work: !workingSwitchNameThu ? null : convertFullDateToTime(workingTimeNameToThu),
      end_work: !workingSwitchNameThu ? null : convertFullDateToTime(workingTimeNameFromThu),
      day_in_week: "4",
      start_lunch: !workingSwitchNameThu || !lunchSwitchNameThu ? null : convertFullDateToTime(lunchTimeNameToThu),
      end_lunch: !workingSwitchNameThu || !lunchSwitchNameThu ? null : convertFullDateToTime(lunchTimeNameFromThu),
    },
    {
      day_type: !workingSwitchNameFri ? "0" : "1",
      start_work: !workingSwitchNameFri ? null : convertFullDateToTime(workingTimeNameToFri),
      end_work: !workingSwitchNameFri ? null : convertFullDateToTime(workingTimeNameFromFri),
      day_in_week: "5",
      start_lunch: !workingSwitchNameFri || !lunchSwitchNameFri ? null : convertFullDateToTime(lunchTimeNameToFri),
      end_lunch: !workingSwitchNameFri || !lunchSwitchNameFri ? null : convertFullDateToTime(lunchTimeNameFromFri),
    },
    {
      day_type: !workingSwitchNameSat ? "0" : "1",
      start_work: !workingSwitchNameSat ? null : convertFullDateToTime(workingTimeNameToSat),
      end_work: !workingSwitchNameSat ? null : convertFullDateToTime(workingTimeNameFromSat),
      day_in_week: "6",
      start_lunch: !workingSwitchNameSat || !lunchSwitchNameSat ? null : convertFullDateToTime(lunchTimeNameToSat),
      end_lunch: !workingSwitchNameSat || !lunchSwitchNameSat ? null : convertFullDateToTime(lunchTimeNameFromSat),
    },
    // {
    //   day_type: "0",
    //   start_work: convertFullDateToTime(workingTimeNameToThu),
    //   end_work: convertFullDateToTime(workingTimeNameFromThu),
    //   day_in_week: "4",
    //   start_lunch: convertFullDateToTime(lunchTimeNameToThu),
    //   end_lunch: convertFullDateToTime(lunchTimeNameFromThu),
    // },
    // {
    //   day_type: "0",
    //   start_work: convertFullDateToTime(workingTimeNameToFri),
    //   end_work: convertFullDateToTime(workingTimeNameFromFri),
    //   day_in_week: "5",
    //   start_lunch: convertFullDateToTime(lunchTimeNameToFri),
    //   end_lunch: convertFullDateToTime(lunchTimeNameFromFri),
    // },
    // {
    //   day_type: "0",
    //   start_work: convertFullDateToTime(workingTimeNameToSat),
    //   end_work: convertFullDateToTime(workingTimeNameFromSat),
    //   day_in_week: "6",
    //   start_lunch: convertFullDateToTime(lunchTimeNameToSat),
    //   end_lunch: convertFullDateToTime(lunchTimeNameFromSat),
    // },
  ];
};

export const ifStringThenTrim = (value: any) => {
  if (typeof value === "string") {
    return value.trim();
  }
  return value;
};

export const filterData = (value: any, arr: any) => {
  const filterValue = arr.filter((item: any) => item.value === value);
  return filterValue?.[0]?.text;
};

export const calculateTotalAmount = (data: any) => {
  const totalAmount = data.reduce((previousValue: any, currentValue: any) => {
    return previousValue + Number(currentValue?.totalPrice);
  }, 0);
  return totalAmount ? totalAmount : 0;
};
export const calculateTotalAmountDetails = (data: any) => {
  const totalAmount = data.reduce((previousValue: any, currentValue: any) => {
    if (currentValue?.quantity_measuring?.toLowerCase() === "bottle" && currentValue?.new_quantity) {
      return previousValue + Number(currentValue?.price) * Number(currentValue?.new_quantity);
    } else {
      return previousValue + Number(currentValue?.price) * Number(currentValue?.quantity);
    }
  }, 0);
  return totalAmount ? totalAmount : 0;
};

export const checkEditableField = (value: string) => {
  const arr = ["4", "3", "11", "5", "6", "7", "8", "13", "10", "14", "9", "15"];
  for (const val of arr) {
    if (val === value) {
      return false;
    }
  }
  return true;
};

export const generateId = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!";
  const length = 10;

  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const date = new Date().toISOString().split("T")[0].replace(/-/g, "");
  return `${date}_${result}`;
};

export const generatePhoneId = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getBenefitLiaisonBalance = (type: string, benefit: any) => {
  if ((type === "Counselling" || type === "Physiotherapy" || type === "Outpatient") && benefit["serviceId"] === 96) {
    return benefit;
  } else if (type === "Inpatient" && benefit["serviceId"] === 98) {
    return benefit;
  } else if (type === "Dental" && benefit["serviceId"] === 99) {
    return benefit;
  }
  return false;
};

export const searchBenefitWithType = (type: string, benefit: any) => {
 
  console.log('rabban 3', type )
  console.log('rabban 4', benefit)

  if (type === "Outpatient" || type === "Counselling" || type === "Physiotherapy") {

    if (benefit?.benefitType?.toLowerCase().includes('outpatient') || benefit?.benefitType?.toLowerCase().includes('all')) {

      return benefit;
    }
  } else if (type === "Dental") {

    if (benefit?.benefitType?.toLowerCase().includes('dental') || benefit?.benefitType.toLowerCase().includes('riders') || benefit?.benefitType?.toLowerCase().includes('all')) {
      return benefit;
    }
  }
};

export const searchBenefitWithPreAuthTypes = (pre_auth_type: string, benefit: any) => {

  if (pre_auth_type == "Outpatient" || pre_auth_type == "Counselling" || pre_auth_type == "Physiotherapy") {
    if (
      benefit.benefitType.toLowerCase().includes("outpatient") || benefit?.benefitType?.toLowerCase().includes('all')
    ) {
      return benefit;
    }
  } else if (pre_auth_type == "Inpatient") {
    if (
      benefit.benefitType.toLowerCase().includes("inpatient") || benefit?.benefitType?.toLowerCase().includes('all')

    ) {
      return benefit;
    }
  } else if (pre_auth_type == "Dental") {
    if (
      benefit.benefitType.toLowerCase().includes("dental") || benefit.benefitType.toLowerCase().includes("riders") || benefit?.benefitType?.toLowerCase().includes('all')

    ) {
      return benefit;
    }
  }
};

export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const calculateAge = function (birthday: any) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const countTotalQuantity = (formulation: string, calculated: number) => {
  let total = 0;
  let multiplier = 1;
  switch (formulation) {
    case "Tablet":
    case "Capsule":
    case "Suppository":
      total = calculated;
      break;
    case "Drop":
      multiplier = 0.05;
      total = calculated;
      break;

    case "Teaspoon":
      multiplier = 5;
      total = calculated;
      break;

    case "Tablespoon":
      multiplier = 15;
      total = calculated;
      break;
    case "Mouth rinse":
      multiplier = 12;
      total = calculated;
      break;

    case "Vial":
      total = calculated;
      break;
    case "Lotion application":
      calculated = 1;
      total = calculated;
      break;
    case "Gel application":
      calculated = 1;
      total = calculated;
      break;
    case "Spray":
      calculated = 1;
      total = calculated;
      break;
    case "Cream application":
      calculated = 1;
      total = calculated;
      break;
    case "Puff":
      calculated = 1;
      total = calculated;
      break;
    case "Ointment application":
      calculated = 1;
      total = calculated;
      break;
    case "Shampoo application":
      calculated = 1;
      total = calculated;
      break;
    default:
      total = calculated;
  }

  total *= multiplier;
  total = Number(total.toFixed(2));

  return total;
};

export const calculationTotalFormulation = (formulationId: string) => {
  const mlsFormulations = ["Drop", "Teaspoon", "Tablespoon", "Mouth rinse", "Mls"];
  const oneToOne = ["Tablet", "Capsule", "Suppository", "Strip", "Piece", "Milligram"];

  const tubeFormulations = [
    "Lotion application",
    "Cream application",
    "Spray",
    "Puff",
    "Ointment application",
    "Shampoo application",
  ];

  const bottelFormulations = ["Gel application"];

  let result = "";
  switch (true) {
    case mlsFormulations.includes(formulationId):
      result = "Ml";
      break;
    case oneToOne.includes(formulationId):
      result = formulationId;
      break;
    case tubeFormulations.includes(formulationId):
      result = "Tube";
      break;
    case bottelFormulations.includes(formulationId):
      result = "Bottle";
      break;
    default:
      result = formulationId;
  }
  return result;
};

export const policyCurrentStatus = (policyStatus: any) => {

  switch (policyStatus) {
    case 1:
      return "Policy active";
    case 2:
      return "Policy expired. Please call " + SUPPORT_NUMBER;
    case 3:
      return "Insufficient balance. Please call " + SUPPORT_NUMBER;
    case 4:
      return "Balance not retrieved. Please call " + SUPPORT_NUMBER;
    case 5:
      return "Sorry, the patient's scheme can not be used to create a Doctor claim.";
    default:
      return "CLAIM NOT ALLOWED";
  }
};

export const convertTranscribeDoctorPrescriptionPayload = ({
  medicineList,
  doctor_id,
  hospital_id,
  finalDiagnosis,
  employeeNumber,
  values,
  otp_not_send,
  medicine_deliver_message
}: any) => {
  const { checked } = values;
  if (medicineList.length === 0) {
    Utils.showAlert(2, "Minimum one medicine required");
    return false;
  }
  if (medicineList && doctor_id && hospital_id && finalDiagnosis) {
    let finalDiagnosisData = "";
    if (finalDiagnosis?.length > 0) {
      let arr = [];
      for (const icd of finalDiagnosis) {
        arr.push(icd?.code);
      }
      finalDiagnosisData = arr.join(",");
    }


    let drugArr: any[] = [];
    for (const med of medicineList) {
      var {
        medicineName,
        global_medicine_search,
        formulation,
        dosage,
        frequency,
        duration,
        foodInstractions,
        supplyfrequency,
        repetitionCycle,
        totalQuantity,
        totalPrice,
      } = med;
      const drug = {
        price: totalPrice,
        doctor_comment: null,
        dosage: dosage,
        food_instruction: foodInstractions,
        form: formulation,
        frequency: frequency,
        duration: duration,
        drug_id: medicineName?.id,
        drug_name: medicineName?.name,
        type: "1",
        global_medicine_search: global_medicine_search ? global_medicine_search : 0,
        pharmacy_quantity: totalQuantity,
        repetition_cycle: repetitionCycle,
        supplying_frequency: supplyfrequency,
      };
      drugArr.push(drug);
    }

    const payloadData = {
      form_step: "2",
      doctor_id: doctor_id,
      hospital_id: hospital_id,
      amount: calculateTotalAmount(medicineList),
      private_practice: checked,
      phone_id: localStorage.getItem("phoneId"),
      os_type: "3",
      // icd10_code_description: "Other bacterial intestinal infections",
      icd10_code: finalDiagnosisData,
      primary_diagnosis_icd10_code: finalDiagnosisData,
      drugs: drugArr,
      employee_number: employeeNumber,
      otp_not_send,
      medicine_deliver_message
      // removedDrugIds: [],
    };
    return payloadData;
  }
};
export const convertTranscribeHospitalPrescriptionPayload = ({
  medicineList,
  doctor_id,
  hospital_id,
  finalDiagnosis,
  employeeNumber,
  values,
  otp_not_send,
  hospital_prescription_order,
  medicine_deliver_message
}: any) => {
  const { checked } = values;
  if (medicineList.length === 0) {
    Utils.showAlert(2, "Minimum one medicine required");
    return false;
  }
  if (medicineList && doctor_id && hospital_id && finalDiagnosis) {
    let finalDiagnosisData = "";
    if (finalDiagnosis?.length > 0) {
      let arr = [];
      for (const icd of finalDiagnosis) {
        arr.push(icd?.code);
      }
      finalDiagnosisData = arr.join(",");
    }


    let drugArr: any[] = [];
    for (const med of medicineList) {
      var {
        medicineName,
        global_medicine_search,
        formulation,
        dosage,
        frequency,
        duration,
        foodInstractions,
        supplyfrequency,
        repetitionCycle,
        totalQuantity,
        totalPrice,
      } = med;
      const drug = {
        price: totalPrice,
        doctor_comment: null,
        dosage: dosage,
        food_instruction: foodInstractions,
        form: formulation,
        frequency: frequency,
        duration: duration,
        drug_id: medicineName?.id,
        drug_name: medicineName?.name,
        type: "1",
        global_medicine_search: global_medicine_search ? global_medicine_search : 0,
        pharmacy_quantity: totalQuantity,
        repetition_cycle: repetitionCycle,
        supplying_frequency: supplyfrequency,
      };
      drugArr.push(drug);
    }

    const payloadData = {
      form_step: "2",
      doctor_id: doctor_id,
      hospital_id: hospital_id,
      amount: calculateTotalAmount(medicineList),
      private_practice: checked,
      phone_id: localStorage.getItem("phoneId"),
      os_type: "3",
      // icd10_code_description: "Other bacterial intestinal infections",
      icd10_code: finalDiagnosisData,
      primary_diagnosis_icd10_code: finalDiagnosisData,
      drugs: drugArr,
      employee_number: employeeNumber,
      otp_not_send,
      hospital_prescription_order,
      medicine_deliver_message
      // removedDrugIds: [],
    };
    return payloadData;
  }
};

export const convertTranscribePrescriptionPayload = ({
  medicineList,
  doctorData,
  hospitalData,
  finalDiagnosis,
  employeeNumber,
  values,
}: any) => {
  const { checked } = values;
  if (medicineList.length === 0) {
    Utils.showAlert(2, "Minimum one medicine required");
    return false;
  }
  if (medicineList && doctorData && (hospitalData || checked) && finalDiagnosis) {
    let finalDiagnosisData = "";
    if (finalDiagnosis?.length > 0) {
      let arr = [];
      for (const icd of finalDiagnosis) {
        arr.push(icd?.code);
      }
      finalDiagnosisData = arr.join(",");
    }


    let drugArr: any[] = [];
    for (const med of medicineList) {
      var {
        medicineName,
        formulation,
        dosage,
        frequency,
        duration,
        foodInstractions,
        supplyfrequency,
        repetitionCycle,
        totalQuantity,
        totalPrice,
        global_medicine_search
      } = med;

      const drug = {
        price: totalPrice,
        doctor_comment: null,
        dosage: dosage,
        food_instruction: foodInstractions,
        form: formulation,
        frequency: frequency,
        duration: duration,
        drug_id: medicineName?.id,
        drug_name: medicineName?.name,
        global_medicine_search: global_medicine_search ? global_medicine_search : 0,
        type: "1",
        pharmacy_quantity: totalQuantity,
        repetition_cycle: repetitionCycle,
        supplying_frequency: supplyfrequency,
      };
      drugArr.push(drug);
    }

    const payloadData = {
      form_step: "2",
      doctor_id: doctorData?.id,
      hospital_id: checked ? "N/A" : hospitalData?.id,
      amount: calculateTotalAmount(medicineList),
      private_practice: checked,
      phone_id: localStorage.getItem("phoneId"),
      os_type: "3",
      // icd10_code_description: "Other bacterial intestinal infections",
      icd10_code: finalDiagnosisData,
      primary_diagnosis_icd10_code: finalDiagnosisData,
      drugs: drugArr,
      employee_number: employeeNumber,
      type_id:1
      // removedDrugIds: [],
    };


    return payloadData;
  }

  // const payloadData = {
  //   user_id: userId,
  //   insurance_company_id: patientInsurance,
  //   claim_date: claimDate,
  //   doctor_id: doctorData?.id,
  //   hospital_id: hospitalData?.id,
  //   patient_name: patientName,
  //   gender: gender,
  //   dob: patientDoB,
  //   full_phone_number: phoneNumber,
  //   os_type: "1",
  //   phone_id: "abc123",
  //   amount: totalPrice,
  //   drugs: drugArr,
  //   entity_id: entityId,
  // };
};
export const getSubBenefitName = (subBenefits: any) => {
  if (subBenefits?.length > 0) {
    let subBenefit = subBenefits.filter((sub: any) => {
      return sub.name?.toLowerCase() == "illness outpatient";
    });
    if (subBenefit?.length > 0) {
      return subBenefit?.[0]?.name;
    } else {
      return subBenefits?.[0]?.name;
    }
  }
  return "";
};

export const formatSubbenefitsToDD = (subBenefits: any) => {

  const sub_benefits = subBenefits?.length > 0 ? subBenefits.map((res: any) => ({
    key: res?.name,
    value: res?.name,
    text: `${res?.name + ' '}(${res?.current_balance ? `KES ` + new Intl.NumberFormat('en-US').format(res?.current_balance) + `/-` : 0})`,
    amount: res?.current_balance ? res?.current_balance : 0,
    sub_benefit_id: res?.sub_benefit_id ? res?.sub_benefit_id : 0
  })) : []
  return sub_benefits

}

export function getFrUniqueIdInLS() {
  let fr_id = localStorage.getItem("frUniqueId");
  if (fr_id === "undefined" || fr_id === "null" || fr_id === "false") return "";
  else return fr_id;
}
export function getPhoneIdFromLS() {
  return localStorage.getItem("phoneId");
}
export function setUserIdInLS(id: string) {
  localStorage.setItem("userId", id);
}
export function getUserIdFromLS() {
  return localStorage.getItem("userId");
}
export function getAccessTokenFromLS() {
  return localStorage.getItem("accessToken");
}
export function getRefreshTokenFromLS() {
  return localStorage.getItem("refreshToken");
}
export function getAppUrlInLS() {
  return localStorage.getItem("branchUrl");
}

/**
 * function to generate axios config for cancel token
 * @param payload
 * @param endPoint
 * @param authorised
 */
const axiosCancelTokenConfig = (payload: any, endPoint: string) => {
  return {
    method: "get",
    timeout: 40000,
    data: payload,
    url: endPoint,
    baseURL: `https://admin.liviaapp.com/api`,
    headers: {
      "Content-Type": "application/json",
      // "LiviaApp-Token": "22e9438557b7c035b09f079c5298952c1782ad8e",
      // "LiviaApp-Token": "ee58136da7e8b8d5dd651e67f01b2564273cd18f",
      ...(localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("accessToken") !== undefined && {
        "LiviaApp-Token": localStorage.getItem("accessToken"),
      }),

      "LiviaApp-language": "en",
      "LiviaApp-timezone": "330",
      "LiviaApp-APIVersion": "3.0",
    },
  };
};

export const getRoleIdFromLS = () => {
  return localStorage.getItem("roleId");
};

export const getCountryCodeFromLS = () => {
  return localStorage.getItem("countryCode");
};

export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const phoneNoValidator = (phoneNumber: string) => {
  // var pattern = new RegExp(/^(?:254|\+254|0)?(7(?:(?:[129][0-9])|(?:0[0-8])|(4[0-1]))[0-9]{6})$/);
  // var pattern = new RegExp(/^[7-9]\d{9}$/);
  let limit = 9;
  let pattern = new RegExp(/^0?\d{9}$/);

  var response = "";
  if (!pattern.test(phoneNumber)) {
    response = `Phone number must be valid and ${limit} digits long.`;
  }
  return response;
};

export const yyyyMMddToDate = () => {
  return moment().format("YYYY-MM-DD");
}

export const convertBannerWebsiteManagementPayload = ({
  bgImg,
  bgHeading,
  bgDiscription,
  bgColor,
  bgHeadingColor,
  bgSubheadingColor,
  headingFontSize,
  subheadingFontSize,
  logoImg,
}: typeof FormField.bannerManagement) => {
  return {
    banner_data: {
      banner_bg_image: bgImg,
      bg_color: bgColor,
      description: bgDiscription,
      heading: bgHeading,
      heading_color: bgHeadingColor,
      heading_fz: headingFontSize,
      sub_heading_color: bgSubheadingColor,
      sub_heading_fz: subheadingFontSize,
    },
    logo: logoImg,
  };
};
export const ifphoneTenDigitThenTrim = (num: string) => {
  let newNum = num;
  if (num?.length === 10) {
    newNum = num.substring(1);
  }
  return newNum;
};

export const checkEClaimStatus = (status: any) => {
  let sta = "";
  statusArray.map((option: any, index: any) => {
    if (option.id === status) sta = option.name;
  });
  return sta;
};

export const checkOrderStatus = (status: any) => {
  let sta = "";
  OrderStatus.map((option: any, index: any) => {
    if (option.id === status) sta = option.name;
  });
  return sta;
};

export const fundedBySomeone = (subbenefit: any, patientInsurance: any) => {
  let fundedTxt = ''
  if (subbenefit?.toLowerCase()?.includes('funded')) {
    fundedTxt = extractFundedByTxt(subbenefit);
  }
  if (patientInsurance == UAP || patientInsurance == EABL) {
    if (subbenefit?.toLowerCase()?.includes('funded') && !subbenefit?.toLowerCase()?.includes('funded by old mutual')) {
      return extractFundedByTxt(subbenefit);
    }
  } else if (patientInsurance == KQ || patientInsurance == JUBILEE) {
    if (subbenefit?.toLowerCase()?.includes('funded') && fundedTxt?.toLowerCase() != 'funded by jubilee' && fundedTxt?.toLowerCase() != 'funded') {
      return extractFundedByTxt(subbenefit);
    }
  } else {
    if (subbenefit?.toLowerCase()?.includes('funded')) {
      return extractFundedByTxt(subbenefit);
    }
  }

  return ''
}
export const formatPaymentMethod = (paytype: string) => {
  const ptype = paytype.toLowerCase()
  const paymentType = { Manually: "manually", Mpesa: "mpesa", Cheque: "cheque", EFT: "eft", Phone: "phone", Paybill: "paybill", Tillnumber: "tillnumber", Tillnumbers: "tillnumbers", Bankaccount: "bankaccount" }
  if (ptype === paymentType.Manually) return "Manually"
  else if (ptype === paymentType.Mpesa) return "Mpesa"
  else if (ptype === paymentType.Cheque) return "Cheque"
  else if (ptype === paymentType.EFT) return "EFT"
  else if (ptype === paymentType.Phone) return "Phone"
  else if (ptype === paymentType.Paybill) return "Pay Bill"
  else if (ptype === paymentType.Tillnumber) return "Till Number"
  else if (ptype === paymentType.Tillnumbers) return "Till Numbers"
  else if (ptype === paymentType.Bankaccount) return "Bank Account"
  else return paytype


}


export const searchSmartBenefitWithType = (pre_auth_type: string, benefit: any) => {
  const OP_Pool_Number = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
  if (pre_auth_type == "Outpatient" || pre_auth_type == "Counselling" || pre_auth_type == "Physiotherapy") {
    if (
      OP_Pool_Number.includes(benefit?.pool_number)
    ) {
      return benefit;
    }
  } else if (pre_auth_type == "Inpatient") {
    if (
      benefit?.pool_number <= 56 && benefit?.pool_number >= 18
    ) {
      return benefit;
    }
  } else if (pre_auth_type == "Dental") {
    if (
      (benefit?.pool_number == 2) || (benefit?.pool_number == 3 && (
        benefit?.name?.toLowerCase()?.includes(" dental") ||
        benefit?.name?.toLowerCase()?.includes("dental ") ||
        benefit?.name?.toLowerCase()?.includes(" dental ") ||
        benefit?.name?.toLowerCase()?.includes("dental/") ||
        benefit?.name?.toLowerCase()?.includes(" dental/") ||
        benefit?.name?.toLowerCase()?.includes("dental")

      ))
    ) {
      return benefit;
    }
  }
};

const checkMemberNumber = (beneficiary: any) => {
  let beneficiaryName = "";
  if (beneficiary?.card_type === "1") {
    beneficiaryName = beneficiary?.entity_id;
  } else if (beneficiary?.card_type === "2") {
    beneficiaryName = beneficiary?.employee_number;
  } else if (beneficiary?.card_type === "3") {
    beneficiaryName = beneficiary?.employee_number;
  } else {
    beneficiaryName = beneficiary?.entity_id;
  }
  return beneficiaryName;
};
export const extractFundedByTxt = (subbenefit: any) => {

  let benefitStr = subbenefit?.toLowerCase()
  if (benefitStr.includes('(') && benefitStr.includes(')')) {
    let fundedbyStr = benefitStr.substring(
      benefitStr.indexOf('(') + 1,
      benefitStr.indexOf(')')
    );
    return fundedbyStr
  }
  return benefitStr

}


export const benefitList = (benefits: any, cardType: any, patientInsurance: any) => {
  const benefitRes =
    benefits?.length > 0 &&
    benefits.filter((benefit: any) => {
      if (benefit) {
        return cardType == 3
          ? getBenefitLiaisonBalanceList(benefit)
          : cardType == 2 && patientInsurance != JUBILEE ? searchSmartBenefitWithTypeList(benefit) : searchBenefitWithPreAuthTypesList(benefit);
      }
    });
  return benefitRes;
}

export const getBenefitLiaisonBalanceList = (benefit: any) => {

  if (benefit["serviceId"] === 96 || benefit["serviceId"] === 99) {
    return benefit;
  }
  return false;
};

export const searchSmartBenefitWithTypeList = (benefit: any) => {
  const OP_Pool_Number = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
  if (
    (OP_Pool_Number.includes(benefit?.pool_number))
  ) {
    return benefit;
  }
};

export const searchBenefitWithPreAuthTypesList = (benefit: any) => {

  if (
    benefit?.benefitType?.toLowerCase()?.includes('all') || benefit?.benefitType?.toLowerCase()?.includes('outpatient') || benefit?.benefitType?.toLowerCase()?.includes('dental') || benefit?.benefitType?.toLowerCase()?.includes('riders')
  ) {
    return benefit;
  }
};

export const getUserBenefits = (type: string, benefits: any, card_type: any, insurance_company_id: any) => {
  console.log('type ', type + ' benefits ' + benefits + ' card_type ' + card_type + ' insurance_company_id ' + insurance_company_id)
  if (card_type == 1 || card_type == 5) {
    return searchBenefitWithType(type, benefits)
  } else if (card_type == 2) {
    if (insurance_company_id != JUBILEE) {
      return searchSmartBenefitWithType(type, benefits)
    } else {
      return searchBenefitWithType(type, benefits)
    }
  } else if (card_type == 3) {
    return getBenefitLiaisonBalance(type, benefits);
  } else if (card_type == 4) {
    return searchMadBenefits(type, benefits)
  };
}

export const subBenefitRequiredCondition = (insurance_company_id: any, card_type: any, subBenefits: any, subbenefit_name: any) => {

  let isBenefitRequired = false;
  let returnStr = ""
  const JubKQ = [JUBILEE, KQ];

  if (JubKQ.includes(insurance_company_id)) {
    if (subBenefits?.length > 0) {
      isBenefitRequired = true;
    }
    if (subBenefits?.length == 0 && card_type == 1) {
      returnStr = "Sub benefits not available."

    }
  } else if (!JubKQ.includes(insurance_company_id) && card_type == 2 && subBenefits?.length == 0) {
    isBenefitRequired = false
  } else if (!JubKQ.includes(insurance_company_id) && card_type == 2 && subBenefits?.length > 0) {
    isBenefitRequired = true
  }

  if (isBenefitRequired && (card_type == 1 || card_type == 2) && (!subbenefit_name || subbenefit_name == '')) {
    returnStr = "Please select sub benefis."
  }
  return returnStr
}

export const searchMadBenefits = (type: any, benefit: any) => {
  if (
    type == "Outpatient" ||
    type == "Counselling" ||
    type == "Physiotherapy"
  ) {
    if (
      (benefit?.name?.toLowerCase()?.includes(" out patient ") ||
        benefit?.name?.toLowerCase()?.includes("out patient ") ||
        benefit?.name?.toLowerCase()?.includes(" out patient") ||
        benefit?.name?.toLowerCase()?.includes("out patient") ||
        benefit?.name?.toLowerCase()?.includes("out patient/") ||
        benefit?.name?.toLowerCase()?.includes(" out patient/") ||
        benefit?.name?.toLowerCase()?.includes(" outpatient") ||
        benefit?.name?.toLowerCase()?.includes("outpatient ") ||
        benefit?.name?.toLowerCase()?.includes(" outpatient ") ||
        benefit?.name?.toLowerCase()?.includes("outpatient") ||
        benefit?.name?.toLowerCase()?.includes("outpatient/") ||
        benefit?.name?.toLowerCase()?.includes(" outpatient/") ||
        benefit?.name?.toLowerCase()?.includes(" out-patient ") ||
        benefit?.name?.toLowerCase()?.includes("out-patient ") ||
        benefit?.name?.toLowerCase()?.includes(" out-patient") ||
        benefit?.name?.toLowerCase()?.includes("out-patient") ||
        benefit?.name?.toLowerCase()?.includes("out-patient/") ||
        benefit?.name?.toLowerCase()?.includes(" out-patient/")) && !benefit?.name?.toLowerCase().includes("in/out")

    ) {
      return benefit;
    }
  } else if (type == "Inpatient") {
    if (
      benefit?.name?.toLowerCase()?.includes(" in patient ") ||
      benefit?.name?.toLowerCase()?.includes("in patient ") ||
      benefit?.name?.toLowerCase()?.includes(" in patient") ||
      benefit?.name?.toLowerCase()?.includes("in patient") ||
      benefit?.name?.toLowerCase()?.includes("in patient/") ||
      benefit?.name?.toLowerCase()?.includes(" in patient/") ||
      benefit?.name?.toLowerCase()?.includes(" inpatient") ||
      benefit?.name?.toLowerCase()?.includes("inpatient ") ||
      benefit?.name?.toLowerCase()?.includes(" inpatient ") ||
      benefit?.name?.toLowerCase()?.includes("inpatient") ||
      benefit?.name?.toLowerCase()?.includes("inpatient/") ||
      benefit?.name?.toLowerCase()?.includes(" inpatient/") ||
      benefit?.name?.toLowerCase()?.includes(" in-patient ") ||
      benefit?.name?.toLowerCase()?.includes("in-patient ") ||
      benefit?.name?.toLowerCase()?.includes(" in-patient") ||
      benefit?.name?.toLowerCase()?.includes("in-patient") ||
      benefit?.name?.toLowerCase()?.includes("in-patient/") ||
      benefit?.name?.toLowerCase()?.includes(" in-patient/")
    ) {
      return benefit;
    }
  } else if (type == "Dental") {
    if (
      benefit?.name?.toLowerCase()?.includes(" dental") ||
      benefit?.name?.toLowerCase()?.includes("dental ") ||
      benefit?.name?.toLowerCase()?.includes(" dental ") ||
      benefit?.name?.toLowerCase()?.includes("dental/") ||
      benefit?.name?.toLowerCase()?.includes(" dental/") ||
      benefit?.name?.toLowerCase()?.includes("dental")

    ) {
      return benefit;
    }
  }
};


export const calculateDistance = (lattitude1: any, longittude1: any, lattitude2: any, longittude2: any) => {
  console.log('lattitude1 ', lattitude1 + 'longittude1 ' + longittude1 + ' lattitude2 ' + lattitude2 + ' longittude2 ' + longittude2)
  var distance = getDistance(
    { latitude: lattitude1, longitude: longittude1 },
    { latitude: lattitude2, longitude: longittude2 },
  );
  return distance
}

export const getLocation = (isSkipped: any) => {
  return new Promise((resolve, reject) => {
    if (isSkipped) {
      let postion = {
        coords: {
          latitude: '',
          longitude: '',
          accuracy: ''
        }
      }
      resolve(postion)
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position);
      }, (error) => {
        reject(2);
      })
    } else {
      reject(1);
    }
  })
}

export const validateRadiusRange = (lat1: any, lan1: any, lat2: any, lan2: any, radiusRange: any, accuracy: any, isSkipped: any) => {

  return new Promise((resolve, reject) => {
    if (isSkipped) {
      resolve(1)
    }

    if (!isSkipped) {
      const distance = calculateDistance(lat1, lan1, lat2, lan2)
      if (distance < radiusRange) {
        resolve(1)
      } else {
        const totalD = parseInt(accuracy) + parseInt(radiusRange)
        if (totalD > distance) {
          resolve(1)
        } else {
          reject(0)
        }
      }
    } else {
      resolve(1)
    }

  })
}

export const getAddress = (newLat: any, newLng: any) => {
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address
        resolve(address)
      },
      (error) => {
        reject(0)
      }
    );
  })
}


export const claimCreateErrMsg = 'Sorry, you are not allowed to (create/edit) the claim as you are not logged in from your registered location. Please login from your registered location and then create/edit claim';
export const preauthCreateErrMsg = 'Sorry, you are not allowed to (create/edit) the pre-auth as you are not logged in from your registered location. Please login from your registered location and then create/edit pre-auth';
export const userDeniedLocationErrMsg = 'We need location services enabled on your browser in order to allow providing services to the patient.Your browser location is disabled currently, please enable it from browser settings and try agay. Please note: if you have already enabled the location on the browser and still seeing this message, please wait for upto 1 minute and try again. If you still have this issue after enabling location, please contact our support team at support@liviahealth.com or call us at 0114 081492.';
export const geoLocationNotSupportedErrMsg = 'Geolocation is not supported by this browser.';
export const FRRegisterErrMsg = 'Sorry, you are not allowed to register the face data as you are not logged in from your registered location. Please login from your registered location and then register the face data.';
export const ManufactureChange = "Sorry, you are not allowed to change the manufacturer as you are already added some order items in cart, Please empty cart first then you can change the manufacturer.";

export const isSubscriptionActive = (subscriptionDetails: any) => {
  // const subscriptionDetails: any = {
  //   isSubscriptionActive: 1,
  //   end_date: "2024-06-16",
  //   grace_period: "10",
  //   upcoming_subscriptions_invoice_id: 1,
  //   current_date: "2024-03-19",
  //   renew_before_days: 7,
  // };
  const expiredMsg = "Your Subscription is expired. ";
  const subsExpiredPreMessage =
    expiredMsg +
    "You are not able to create Preauth and Claims in our system. Please contact us at support@liviahealth.com or call on ";
  const subsExpiredPostMessage = " to activate your subscription ";
  let subscriptionErrorMessage = "";
  let postsubscriptionErrorMessage = "";
  let isActive = true;

  if (subscriptionDetails?.isSubscriptionActive != undefined) {

    const today = subscriptionDetails.current_date;
    const todayDate = moment(today).format("YYYY-MM-DD");
    const endDate = moment(subscriptionDetails.end_date).format("YYYY-MM-DD");
    const graceDate = moment(subscriptionDetails.end_date)
      .add(subscriptionDetails.grace_period, "days")
      .format("YYYY-MM-DD");
    const renewBeforeDate = moment(endDate)
      .subtract(subscriptionDetails.renew_before_days, "days")
      .format("YYYY-MM-DD");
    const remaingDays =
      subscriptionDetails.grace_period -
      moment(today, "YYYY-MM-DD").diff(endDate, "days");

    if (
      (subscriptionDetails?.end_date == null
        || subscriptionDetails.isSubscriptionActive != 1)
    ) {
      isActive = false;
      subscriptionErrorMessage =
        subsExpiredPreMessage + SUPPORT_NUMBER + subsExpiredPostMessage;
      postsubscriptionErrorMessage = "";
    } else if (graceDate < today) {
      isActive = false;
      subscriptionErrorMessage =
        expiredMsg +
        "You are not able to create Preauth and Claims in our system. Please ";
      postsubscriptionErrorMessage = " to activate your subscription.";
      if (!subscriptionDetails.upcoming_subscriptions_invoice_id) {
        subscriptionErrorMessage =
          subsExpiredPreMessage + SUPPORT_NUMBER + subsExpiredPostMessage;
        postsubscriptionErrorMessage = "";
      }
    } else if (endDate == todayDate) {
      subscriptionErrorMessage = "Your Subscription is going to expire today.";
      postsubscriptionErrorMessage = " to renew your subscription.";
    } else if (graceDate == today) {
      subscriptionErrorMessage =
        expiredMsg +
        "You can still use our services until today as a grace period. Please ";
      postsubscriptionErrorMessage = " to reactivate your subscription";
    } else if (endDate < todayDate && todayDate <= graceDate) {
      subscriptionErrorMessage =
        expiredMsg +
        "You can still use our services until next " +
        remaingDays +
        " days as a grace period. Please ";
      postsubscriptionErrorMessage = " to reactivate your subscription";
    } else if (renewBeforeDate <= todayDate && endDate > todayDate) {
      subscriptionErrorMessage = "Your Subscription is going to expire soon.";
      postsubscriptionErrorMessage = " to renew your subscription.";
    }
  }

  return { subscriptionErrorMessage, postsubscriptionErrorMessage, isActive };
};

export const numberCommaFormat = (x: any, a: any = false) => {
  if (a) {
    console.log('xxxx', a)
  }
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0.0;
};

export const calculateSubscriptionAmount = (timeline: any, duration: any, price: any, subscriptionSetting: any) => {
  let linkage_fee: number = subscriptionSetting?.linkage_fee;
  const portal_fee = price;
  const insurance_companies_count =
    subscriptionSetting?.insurance_company_count;
  const vat = subscriptionSetting?.vat;

  //Needs to calculat months form timeline
  let months = 1;
  if (timeline == "years") {
    months = 12;
  }
  const subscription_months: number = duration * months;

  linkage_fee = subscription_months * linkage_fee;
  const total_linkage_fee = insurance_companies_count * linkage_fee;
  const sub_total = parseFloat(portal_fee) + total_linkage_fee;
  const total = sub_total + (sub_total * vat) / 100;
  return {
    total: numberCommaFormat(total),
    vat_amount: numberCommaFormat((sub_total * vat) / 100),
    linkage_fee: numberCommaFormat(total_linkage_fee),
    sub_total: numberCommaFormat(sub_total),
    duration: subscription_months,
  };
};

export const calculateStartEndDate = (timeline: any, duration: any, current_end_date: any) => {
  let startdate = moment(current_end_date).add(1, "days").format("Do MMM YYYY");
  let start_date = moment(current_end_date).add(1, "days").format("YYYY MM DD");
  let end_date = moment(start_date)
    .add(duration, timeline)
    .subtract(1, "days")
    .format("Do MMM YYYY");
  return { startdate, end_date };
};



const CommonFunctions = {
  ManufactureChange,
  calculateStartEndDate,
  calculateSubscriptionAmount,
  numberCommaFormat,
  isSubscriptionActive,
  retry,
  PageSwitch,
  getRandomInt,
  removeSpaces,
  saveData,
  checkFileSize,
  checkFileType,
  checkEFileType,
  axiosCancelTokenConfig,
  calculateAge,
  getUserIdFromLS,
  getRoleIdFromLS,
  getCountryCodeFromLS,
  isObjectEmpty,
  phoneNoValidator,
  fundedBySomeone,
  searchSmartBenefitWithType,
  checkMemberNumber,
  benefitList,
  subBenefitRequiredCondition,
  getUserBenefits

};

export default CommonFunctions;
