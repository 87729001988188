import { useState } from "react";
import { Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
// import {onSubmitOtp} from "../../action"
import Utils from "../../../../utils"
// import {useNavigate} from "react-router-dom"
const useStyles = makeStyles(() =>
  createStyles({
    otpBox: {
      width: "100%",
      marginTop: "16px",
      display: 'flex',
      justifyContent: 'space-between'
    },
    otpTextField: {
      width: "100% !important",
      height: "50px",
      borderRadius: "10px",
      backgroundColor: "rgba(23,143,201,0.05)",
      fontFamily: `"Rubik", sans-serif`,
      fontSize: "36px",
      lineHeight: "0.89",
      color: "var(--blue-grey)",
      border: "0px",
      margin: '10px'
    },
    otpContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: '-10px'
    },
    removeFocus: {
      outline: "none",
    },
  })
);

export default function OtpField(props:any) {
  const classes = useStyles();
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  // const navigate= useNavigate()
  const inputCapture = (value: any) => {
    setOtp(value);
    if (!Utils.constants.spaceRegex.test(value)) {
       Utils.showAlert(3, "BLANK_SPACE");
       return false
    }
    dispatch({
      type: Utils.ActionName.UPDATE_OTP_VAL,
      payload: { otp: value},
    });
    
  };
  return (
    <>
      <Box className={classes.otpBox}>
        <OtpInput
          value={otp}
          containerStyle={classes.otpContainer}
          inputStyle={classes.otpTextField}
          onChange={(value: any) => inputCapture(value)}
          numInputs={props?.numInputs ? props?.numInputs:4}
          focusStyle={classes.removeFocus}
          shouldAutoFocus={true}
            isInputNum={true}
        />
      </Box>
    </>
  );
}
